import React from 'react';
import p from './TOKIUM.svg';

/**
 * ファビコンを設定します。
 */
export const Favicon: React.FC = () => {
  const link = document.getElementById('Favicon') as HTMLLinkElement;
  link.href = p;

  return null;
};
